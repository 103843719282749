export const SuccessIcon = ({ color = '#59b300', stroke = 'white' }) => (
  <svg width='70' height='70' viewBox='0 0 70 70' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <circle cx='35' cy='35' r='35' fill={color} />
    <path
      d='M21 34.8152L30.4554 44.3327L49 25.666'
      stroke={stroke}
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)
