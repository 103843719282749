import styled from 'styled-components'

export const PaymentMethodsWrapper = styled.div`
  margin-bottom: 0;

  .row {
    font-size: 1.8rem;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;
    margin-top: 2rem;
    :first-of-type {
      margin-top: 0;
    }

    .label {
      color: #808080;
      font-size: 1.3rem;
      line-height: 100%;
      margin-bottom: 0.5rem;
    }
  }
`

export const PaymentMethodsEmpty = styled.div`
  color: #808080;
  font-style: normal;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
`

export const PaymentMethodsTitle = styled.div`
  margin-bottom: 2.5rem;
  text-align: left;

  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.4rem;
`

export const PaymentMethodsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

  &.use-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 3rem;
  }
`

export const SingleMethodWrapper = styled.div`
  border: 1.5px solid #000000;
  border-radius: 6px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  padding: 1.5rem 2.5rem 1.5rem 2rem;
`

export const SingleMethodName = styled.div`
  color: #808080;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`
