import styled from 'styled-components'

export const MerchantPaneWrapper = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(44px);
  border-radius: 7px;
  color: #ffffff;

  .left {
    background: transparent;
    padding: 3rem 3rem 2.5rem;
    font-size: 3.6rem;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;

    .value {
      font-size: 3.6rem;
      font-weight: normal;
      line-height: 100%;
      margin-top: 2rem;
    }
  }

  .right {
    background: rgba(0, 0, 0, 0.25);
    padding: 1.4rem 3rem 3rem;

    display: flex;
    flex-flow: column nowrap;
    align-items: flex-start;

    font-size: 1.8rem;
    font-style: normal;
    font-weight: normal;
    line-height: 100%;

    .row {
      margin-top: 2rem;
      :first-of-type {
        margin-top: 0;
      }
    }

    .label {
      color: #808080;
      font-size: 1.3rem;
      line-height: 100%;
      margin-bottom: 0.5rem;
      text-transform: none;
    }
  }
`

export const MerchantPaneAmountContainer = styled.div`
  background: transparent;
  border-radius: 7px 7px 0 0;
  padding: 3rem 3rem 2.5rem;

  font-size: 3.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;

  .value {
    font-size: 3.6rem;
    font-weight: normal;
    line-height: 100%;
    margin-top: 2rem;
  }
`

export const MerchantPaneInfoContainer = styled.div`
  background: rgba(0, 0, 0, 0.25);
  border-radius: 0 0 7px 7px;
  padding: 1.4rem 3rem 3rem;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;

  font-size: 1.8rem;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;

  .row {
    margin-top: 2rem;
    :first-of-type {
      margin-top: 0;
    }
  }

  .label {
    color: #808080;
    font-size: 1.3rem;
    line-height: 100%;
    margin-bottom: 0.5rem;
    text-transform: none;
  }

  .subtitle {
    color: #000000;
    font-size: 1.8rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.2rem;
    margin-bottom: 1.5rem;
  }
`

export const MerchantPaneLogoContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  .logo {
    max-height: 8rem;
    max-width: 20rem;
    margin-right: -2rem;
  }

  .fee {
    color: #678899;
    font-size: 12px;
    font-style: italic;
    font-weight: 400;
    line-height: 15px;
  }
`
