import { ReactNode, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { LanguageMenu } from '../LanguageMenu'
import { ThemeContext } from 'styled-components'
import { ThunkDispatch } from 'redux-thunk'
import { CheckoutHeader, CheckoutWrapper } from './CheckoutContainer.styled'

import { actions, useSessionId } from 'mmfintech-checkout-commons'

type CheckoutContainerProps = {
  className?: string
  hideHeader?: boolean
  hideLanguage?: boolean
  children?: ReactNode
}

export const CheckoutContainer = ({
  className,
  hideHeader = false,
  hideLanguage = false,
  children
}: CheckoutContainerProps) => {
  const themeContext = useContext(ThemeContext)

  const { sessionId } = useSessionId()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  const handleLanguageChanged = (code: string) => {
    void dispatch(actions.common.storeSelectedLanguage(sessionId, code))
  }

  return (
    <CheckoutWrapper className={className}>
      {hideHeader ? null : (
        <CheckoutHeader>
          <span className='logo'>
            <img src={themeContext.brandLogo} alt='Logo' />
          </span>
          {hideLanguage ? null : (
            <span className='right'>
              <LanguageMenu onChange={handleLanguageChanged} />
            </span>
          )}
        </CheckoutHeader>
      )}
      {children}
    </CheckoutWrapper>
  )
}
