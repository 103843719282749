import styled from 'styled-components'

import SpeedyBackground1 from '../../assets/images/speedy-background-1.png'
import SpeedyBackground2 from '../../assets/images/speedy-background-2.png'
import SpeedyBackground3 from '../../assets/images/speedy-background-3.png'
import SpeedyBackground4 from '../../assets/images/speedy-background-4.png'

export const ContentWrapperStyled = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1490px) 1fr;
  grid-template-rows: 6rem auto;
  grid-template-areas: 'header header header' 'left-column main-content right-column' 'footer footer footer';
  grid-row-gap: 0;

  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: 'Helvetica Now Display', sans-serif;
  background-color: #000000;

  @media (min-width: 1175px) {
    grid-template-rows: 7rem auto;
  }

  & > div:first-of-type {
    animation: fadein 0.75s;
  }

  .fluid & {
    @media (min-width: 1175px) {
      grid-template-rows: 9rem auto;
    }
  }

  .speedy-background-1,
  .speedy-background-2,
  .speedy-background-3,
  .speedy-background-4 {
    background-color: #000000;
    background-repeat: repeat-y;
    background-position: center top;
    background-size: cover;
  }
  .speedy-background-1 {
    //prettier-ignore
    background-image: url("${SpeedyBackground1}");
  }
  .speedy-background-2 {
    //prettier-ignore
    background-image: url("${SpeedyBackground2}");
  }
  .speedy-background-3 {
    //prettier-ignore
    background-image: url("${SpeedyBackground3}");
  }
  .speedy-background-4 {
    //prettier-ignore
    background-image: url("${SpeedyBackground4}");
  }
`
