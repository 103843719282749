import styled from 'styled-components'

export const PaymentContainer = styled.div`
  background: #ffffff;
  backdrop-filter: blur(44px);
  border-radius: 7px;
  position: relative;

  min-height: 20rem;
  margin-top: 2rem;
  padding: 3rem 3rem 4rem;

  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 100%;

  .lower-input-section {
    display: flex;
    flex-flow: row nowrap;
    .input-wrapper {
      .left-icon {
        padding-left: 3.5rem;
      }
      &:last-of-type {
        border-left: 1px solid rgba(238, 238, 238, 1);
        .input-inner-wrapper {
          padding: 0 1rem;
        }
      }
    }
  }

  .input-wrapper {
    width: auto;
    margin-left: -3rem;
    margin-right: -3rem;
  }

  .buttons {
    margin-top: 3.5rem;

    button {
      height: 5.3rem;
      font-size: 2.2rem;
      font-weight: 500;
      line-height: 100%;

      margin-bottom: 0.8rem;
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`

export const CancelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2.5rem 0 2rem;
  width: 100%;

  font-size: 2.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  a {
    color: #8f99a3;
    text-decoration: none;

    &:hover {
      color: #ffffff;
    }
  }
`
