import styled from 'styled-components'

export const CheckoutWrapper = styled.div`
  grid-row: 1 / 4;
  grid-column: left-column / right-column;

  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;

  .err-msg-wrapper {
    justify-content: center;
  }
`

export const CheckoutHeader = styled.div`
  width: 100%;
  height: 12rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  .logo {
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
  }

  .right {
    display: grid;
    place-items: center flex-end;
    margin-right: 2rem;
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);

    .logo {
      grid-column: 2;
    }

    .right {
      margin-right: 6rem;
    }
  }
`
