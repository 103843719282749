import { useHistory } from 'react-router-dom'

import { PreviewPix } from './PreviewPix'
import { PreviewFrame } from './PreviewFrame'
import { PreviewCrypto } from './PreviewCrypto'
import { PreviewMessage } from './PreviewMessage'

import { isProduction } from '../../settings'
import { isMobileDevice, redirectToExternalUrl } from 'mmfintech-commons'

export const DepositResult = ({ response, onBack, logEvent }) => {
  const { result, resultType, processingAmount, status } = response || {}

  const history = useHistory()

  if (status === 'FAILED') {
    history.push('/fail')
    return
  }

  switch (resultType) {
    // case 'INSTRUCTIONS':
    // case 'BANKWIRE_DETAILS':
    // case 'INTERNAL_EXECUTION':
    // case 'CRYPTO_ADDRESS_DETAILS':

    case 'MESSAGE':
      return <PreviewMessage result={result} />

    case 'REDIRECT_URL':
      logEvent('redirected_to_provider')
      if (typeof result === 'string') {
        redirectToExternalUrl(result)
      } else {
        const { redirectUrl } = result || {}
        redirectToExternalUrl(redirectUrl)
      }
      break

    case 'QR_CODE':
      return <PreviewPix value={result} />

    case 'CHECKOUT_CRYPTO_ADDRESS_DETAILS':
      return <PreviewCrypto data={{ ...result, ...{ processingAmount } }} onBack={onBack} />

    case 'IFRAME_URL':
      if (isMobileDevice()) {
        logEvent('redirected_to_provider')
        redirectToExternalUrl(result)
      } else {
        logEvent('open_provider_in_frame')
        return <PreviewFrame result={result} />
      }
      break

    default:
      if (!isProduction && resultType) {
        console.log(`DepositResult: Unknown result type "${resultType}"`)
      }
  }

  return null
}
