// noinspection SpellCheckingInspection

import styled from 'styled-components'

import bankTransferLogo from '../../assets/methods/bank-transfer.svg'
import bankyLogo from '../../assets/methods/banky.png'
import bcpCashLogo from '../../assets/methods/bcp-cash.png'
import boletoLogo from '../../assets/methods/boleto.png'
import btvoucherLogo from '../../assets/methods/btvoucher.png'
import cellPowerCashLogo from '../../assets/methods/cell-power.png'
import creditCardLogo from '../../assets/methods/credit-card.png'
import cryptoLogo from '../../assets/methods/crypto.svg'
import efectyCashLogo from '../../assets/methods/efecty.png'
import indianNetBankingLogo from '../../assets/methods/indian-bank.png'
import instantBankTransferLogo from '../../assets/methods/instant-bank-transfer.svg'
import jetonLogo from '../../assets/methods/jeton.png'
import khipuLogo from '../../assets/methods/khipu.png'
import kingdomWalletLogo from '../../assets/methods/kingdom-wallet.png'
import pagoEfectivoLogo from '../../assets/methods/pago-efectivo.png'
import paycashCashLogo from '../../assets/methods/paycash_cash.png'
import paynetCashLogo from '../../assets/methods/paynet_cash.png'
import pecFlashLogo from '../../assets/methods/pec-flash.png'
import pixLogo from '../../assets/methods/pix.png'
import safetyPayLogo from '../../assets/methods/safetypay.png'
import upiLogo from '../../assets/methods/upi.png'
import yapeLogo from '../../assets/methods/yape.png'

export const ButtonWrapper = styled.div`
  cursor: pointer;
  position: relative;

  width: 100%;
  max-width: 13rem;
  padding: 0;

  .use-grid & {
    max-width: unset;
  }

  display: flex;
  align-items: center;
  flex-flow: column nowrap;

  color: #808080;
  font-size: 1.4rem;
  font-style: normal;
  font-weight: normal;
  line-height: 120%;
  text-align: center;

  &.selected {
    color: #000000;
  }

  :hover {
    color: #000000;
  }
`

export const PaymentMethodChecked = styled.span`
  position: absolute;
  top: 0;
  right: -10px;
  transform: translate(2px, -8px);
`

export const PaymentMethodImage = styled.span`
  position: relative;
  width: 100%;
  height: 7.5rem;
  border: 2px solid #eeeeee;
  border-radius: 6px;
  margin-bottom: 0.5rem;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;

  &.selected {
    border: 2px solid #000000;
  }

  &.image-bankwire {
    //prettier-ignore
    background-image: url("${bankTransferLogo}");
  }
  &.image-banky {
    //prettier-ignore
    background-image: url("${bankyLogo}");
  }
  &.image-bcp_cash {
    //prettier-ignore
    background-image: url("${bcpCashLogo}");
  }
  &.image-boleto {
    //prettier-ignore
    background-image: url("${boletoLogo}");
  }
  &.image-btvoucher {
    //prettier-ignore
    background-image: url("${btvoucherLogo}");
  }
  &.image-cell_power_cash {
    //prettier-ignore
    background-image: url("${cellPowerCashLogo}");
  }
  &.image-credit_card {
    //prettier-ignore
    background-image: url("${creditCardLogo}");
  }
  &.image-instant_crypto {
    //prettier-ignore
    background-image: url("${cryptoLogo}");
  }
  &.image-efecty_cash {
    //prettier-ignore
    background-image: url("${efectyCashLogo}");
  }
  &.image-indian_net_banking {
    //prettier-ignore
    background-image: url("${indianNetBankingLogo}");
  }
  &.image-community_instant_bank_transfer,
  &.image-direct_instant_bank_transfer,
  &.image-instant_bank_transfer {
    //prettier-ignore
    background-image: url("${instantBankTransferLogo}");
  }
  &.image-jeton_cash,
  &.image-jeton {
    //prettier-ignore
    background-image: url("${jetonLogo}");
  }
  &.image-khipu {
    //prettier-ignore
    background-image: url("${khipuLogo}");
  }
  &.image-kingdom_wallet {
    //prettier-ignore
    background-image: url("${kingdomWalletLogo}");
  }
  &.image-pago_efectivo_bank,
  &.image-pago_efectivo_cash {
    //prettier-ignore
    background-image: url("${pagoEfectivoLogo}");
  }
  &.image-paycash_cash {
    //prettier-ignore
    background-image: url("${paycashCashLogo}");
  }
  &.image-paynet_cash {
    //prettier-ignore
    background-image: url("${paynetCashLogo}");
  }
  &.image-pec_flash {
    //prettier-ignore
    background-image: url("${pecFlashLogo}");
  }
  &.image-pix {
    //prettier-ignore
    background-image: url("${pixLogo}");
  }
  &.image-safetypay_bank,
  &.image-safetypay_cash {
    //prettier-ignore
    background-image: url("${safetyPayLogo}");
  }
  &.image-upi {
    //prettier-ignore
    background-image: url("${upiLogo}");
  }
  &.image-yape {
    //prettier-ignore
    background-image: url("${yapeLogo}");
  }

  &.icon {
    width: 7rem;
    height: 3.5rem;
    padding: 0;
    margin: 0;
    background-position: right center;
    background-color: transparent;
    background-size: contain;
    border-radius: 0;
    border: none;
  }
`
