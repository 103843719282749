import { useEffect, Suspense } from 'react'

import { useDispatch } from 'react-redux'
import { Route, Switch, Redirect, BrowserRouter as Router, RouteProps } from 'react-router-dom'

import styled, { ThemeProvider } from 'styled-components'
import { withTranslation } from 'react-i18next'
import { Toaster } from 'react-hot-toast'
import { ErrorBoundary } from 'react-error-boundary'

import { ContentWrapper } from './components'
import { Button, Preloader } from 'mmfintech-portal-commons'

import theme from './theme'
import routes from './routes'
import settings from './settings'
import { actions, configuration } from 'mmfintech-checkout-commons'

import ErrorIcon from './assets/icons/error-icon.svg?react'
import { ThunkDispatch } from 'redux-thunk'

const AppInner = () => {
  useEffect(() => {
    configuration.createBackendConfig(settings.backendForLocalhost)
    // configuration.createCardGatewayConfig(settings.)
    // eslint-disable-next-line
  }, [])

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <ContentWrapper>
          <Switch>
            {routes.map((route, index) => {
              const { path, exact, redirect, component } = route

              if (redirect) {
                return (
                  <Route key={index} exact={exact} path={path}>
                    <Redirect to={redirect} />
                  </Route>
                )
              }

              if (component) {
                return <CustomRoute key={index} path={path} exact={exact} component={component} />
              }

              return null
            })}
          </Switch>
        </ContentWrapper>

        <Toaster
          position='top-right'
          containerStyle={{
            top: '10rem'
          }}
          toastOptions={{
            className: '',
            style: {
              color: '#000000',
              fontFamily: 'inherit',
              fontSize: '1.4rem',
              fontStyle: 'normal',
              padding: '1.5rem',
              borderRadius: '0'
            },
            success: {}
          }}
        />
      </Router>
    </ThemeProvider>
  )
}

const ErrorFallback = ({ resetErrorBoundary }) => {
  return (
    <AlertWrapper>
      <Alert>
        <ErrorIcon />
        <p>An unexpected error occurred</p>
        {resetErrorBoundary ? (
          <Button color='alternative' onClick={resetErrorBoundary} text='Go back to the home page' />
        ) : null}
      </Alert>
    </AlertWrapper>
  )
}

const ThisApp = withTranslation()(AppInner)

const App = () => {
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()

  return (
    <Suspense
      fallback={
        <PreloaderWrapper>
          <Preloader />
        </PreloaderWrapper>
      }>
      <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onError={(error: Error, info: { componentStack: string }) =>
          void dispatch(actions.common.errorLogging(error.toString(), info))
        }
        onReset={() => window.location.replace('/')}>
        <ThisApp />
      </ErrorBoundary>
    </Suspense>
  )
}

export default App

const PreloaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;
`

const AlertWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  margin-top: 8rem;
`

const Alert = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  max-width: 50rem;
  width: 100%;
  border-radius: 1rem;
  padding: 3rem;

  text-align: center;
  font-size: 16px;

  img {
    width: 66px;
    height: 38px;
  }
  .button {
    width: 100%;
    max-width: 30rem;
  }
`

const CustomRoute = ({ component: Component, ...rest }: RouteProps) => (
  <Route {...rest} render={props => <Component {...props} />} />
)
