import styled from 'styled-components'

export const CheckoutContent = styled.div`
  width: 95vw;
  max-width: 45rem;
  margin-bottom: 3rem;

  display: flex;
  flex-flow: column nowrap;
`

export const MessageWrapper = styled.div`
  margin-bottom: 2rem;
  padding: 2rem 3rem;
  border-radius: 7px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  color: #000000;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: normal;

  img,
  svg {
    margin-right: 2rem;
  }

  &.success {
    background: #00ff66;
    backdrop-filter: blur(44px);
  }
  &.failure {
    color: white;
    background: #ff0431;
    backdrop-filter: blur(44px);
  }

  .content {
    .timer {
      //color: #1a1a1a;
      font-size: 1.3rem;
      line-height: 120%;
    }
  }
`
