import { cryptoCurrencyNotes, tr } from 'mmfintech-commons'

import { WarningMessageTitle, WarningMessageWrapper } from './WarningMessage.styled'

import InfoIcon from '../../assets/icons/info-circle-black.svg?react'

export const WarningMessage = ({ currency }) => {
  const notes = cryptoCurrencyNotes(currency)

  if (notes) {
    return (
      <WarningMessageWrapper>
        <WarningMessageTitle>
          <InfoIcon />
          {tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.PLEASE_NOTE', 'Please note')}
        </WarningMessageTitle>

        {notes.map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </WarningMessageWrapper>
    )
  }

  return null
}
