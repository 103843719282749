import QRCode from 'qrcode.react'

import { Button } from 'mmfintech-portal-commons'
import { AmountValue, LabelValue, WarningMessage } from '../../../components'
import { CryptoPreviewTitle, CryptoPreviewWrapper } from './PreviewCrypto.styled'

import { tr } from 'mmfintech-commons'

export const PreviewCrypto = ({ data, onBack }) => {
  const { address, currency, tag, processingAmount } = data || {}

  return (
    <>
      <CryptoPreviewWrapper>
        <CryptoPreviewTitle>{tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.TITLE', 'Details')}</CryptoPreviewTitle>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.ADDRESS', 'Deposit address')}
          value={address}
          enableCopy={true}
          dataTest='address'
        />

        <p className='text-center code bordered'>
          <QRCode value={address} renderAs='svg' size={150} />
        </p>

        <LabelValue
          label={tr('FRONTEND.DEPOSIT.CRYPTO_DETAILS.TAG', 'Tag')}
          value={tag}
          dataTest='tag'
          enableCopy={true}
        />

        <AmountValue amount={processingAmount} currency={currency} />

        {typeof onBack === 'function' && (
          <Button
            type='button'
            color='secondary'
            className='mt-2'
            text={tr('FRONTEND.BUTTONS.BACK', 'Back')}
            onClick={onBack}
            data-test='button-back'
          />
        )}
      </CryptoPreviewWrapper>

      <WarningMessage currency={currency} />
    </>
  )
}
