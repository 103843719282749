import { shallowEqual, useSelector } from 'react-redux'

import { formatMoney, tr } from 'mmfintech-commons'
import { AmountValueWrapper } from './AmountValue.styled'

export const AmountValue = ({ amount, currency }) => {
  const { currencies } = useSelector(({ common: { currencies } }) => ({ currencies }), shallowEqual)

  return (
    <AmountValueWrapper>
      <div className='amount-label'>{tr('FRONTEND.BANKING.COMMON.AMOUNT_LABEL', 'Amount')}</div>
      <span data-test='amount' className='amount'>
        {formatMoney(amount, currency, currencies)}
      </span>
    </AmountValueWrapper>
  )
}
