import styled from 'styled-components'

export const AmountValueWrapper = styled.div`
  font-size: 1.6rem;
  line-height: 1.9rem;
  height: 65px;
  margin-bottom: 2rem;
  width: 100%;

  .amount-label {
    margin-bottom: 0.5rem;
  }
`
