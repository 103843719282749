import cn from 'classnames'

import { tr } from 'mmfintech-commons'
import { ButtonWrapper, PaymentMethodChecked, PaymentMethodImage } from './PaymentMethodButton.styled'

import checkedIcon from '../../assets/icons/checked.svg'

export const PaymentMethodButton = ({ name, selected, onClick }) => (
  <ButtonWrapper className={cn({ selected })} onClick={onClick} data-test='button-payment-method'>
    <PaymentMethodImage className={cn('image-' + name.toLowerCase(), { selected })}>
      {selected && (
        <PaymentMethodChecked>
          <img src={checkedIcon} alt='' />
        </PaymentMethodChecked>
      )}
    </PaymentMethodImage>
    {tr('METADATA.PAYMENT_OPTIONS.' + name, name)}
  </ButtonWrapper>
)
