import { useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { MerchantPane } from './MerchantPane'
import { DepositResult } from '../DepositResult'
import { Button, ErrorDisplay, Preloader } from 'mmfintech-portal-commons'
import { CancelContainer, PaymentContainer } from './Checkout.styled'
import { CheckoutContainer, CheckoutContent, CustomInput, SelectMethod } from '../../components'

import settings from '../../settings'

import { isEmptyString, isValidArray, tr, translateCountryName } from 'mmfintech-commons'
import {
  actions,
  CheckoutFlowStep,
  useCheckout,
  useLanguageParam,
  useSessionId,
  useUnloadBeacon
} from 'mmfintech-checkout-commons'

import { ThunkDispatch } from 'redux-thunk'
import { CountryChoice, CurrencyChoice, PaymentSessionStatusEnum, SupportedBank } from 'mmfintech-commons-types'

const Checkout = () => {
  useLanguageParam(settings.languages)
  const { sessionId } = useSessionId()

  const {
    banks,
    checkoutCountries,
    checkoutCountriesError,
    checkoutCurrencies,
    checkoutError,
    checkoutPay,
    checkoutPayFetching,
    session,
    sessionFetching
  } = useSelector(
    ({
      common: { selectedLanguage },
      checkout: {
        banks,
        checkoutCountries,
        checkoutCountriesError,
        checkoutCurrencies,
        checkoutError,
        checkoutPay,
        checkoutPayFetching,
        session,
        sessionFetching
      }
    }) => ({
      banks,
      checkoutCountries,
      checkoutCountriesError,
      checkoutCurrencies,
      checkoutError,
      checkoutPay,
      checkoutPayFetching,
      selectedLanguage,
      session,
      sessionFetching
    }),
    shallowEqual
  )

  const { sessionStatus, paymentResult, paymentResultType, processingAmount, processingCurrency, showCancelButton } =
    session || {}

  const history = useHistory()
  const dispatch: ThunkDispatch<Promise<void>, any, any> = useDispatch()
  useUnloadBeacon({ sessionId })

  const {
    step,
    fields,
    status,
    logEvent,
    handlePay,
    formValues,
    countryCode,
    setCountryCode,
    paymentMethod,
    setPaymentMethod,
    handleStartOver,
    reloadSession
  } = useCheckout({
    enableLogging: true,
    initialStep: CheckoutFlowStep.SELECT_METHOD
  })

  const handleCancelClick = () => {
    if (sessionId) {
      void dispatch(actions.checkout.cancelSessionPayment(sessionId))
    }
    logEvent('cancelled_by_payer')
    history.push('/fail')
    return false
  }

  const handleBankSelected = (code: string) => {
    logEvent('bank_selected', code)
  }

  const parseResult = () => {
    try {
      return JSON.parse(paymentResult)
    } catch (error) {
      return paymentResult
    }
  }

  const prepareResponse = () =>
    sessionStatus?.value === PaymentSessionStatusEnum.IN_PROGRESS
      ? { result: parseResult(), resultType: paymentResultType, processingAmount, processingCurrency }
      : checkoutPay

  useEffect(() => {
    if (checkoutError) {
      const { errorKey } = checkoutError || {}
      if (errorKey === 'MERCHANTS.ERROR.PAYMENT_ALREADY_COMPLETED') {
        reloadSession()
      }
      formValues.handleErrors(checkoutError)
    }
    // eslint-disable-next-line
  }, [checkoutError])

  useEffect(() => {
    console.log(step)
  }, [step])

  return (
    <CheckoutContainer className='speedy-background-2'>
      <CheckoutContent>
        <ErrorDisplay error={[checkoutError, checkoutCountriesError]} />

        {(step === CheckoutFlowStep.SELECT_METHOD || step === CheckoutFlowStep.ADDITIONAL_FIELDS) && (
          <MerchantPane session={session} />
        )}

        {sessionFetching && (
          <PaymentContainer>
            <Preloader />
          </PaymentContainer>
        )}

        {!sessionFetching &&
          status === 'IDLE' &&
          (step === CheckoutFlowStep.SELECT_METHOD || step === CheckoutFlowStep.ADDITIONAL_FIELDS) && (
            <>
              <PaymentContainer>
                {checkoutCountries?.length > 1 ? (
                  <div className='mb-3'>
                    <CustomInput
                      type='select'
                      placeholder={tr('CHECKOUT.PAYMENT.COUNTRY_PLACEHOLDER', 'Select country')}
                      options={checkoutCountries?.map((country: CountryChoice) => ({
                        value: country.countryCode,
                        label: country.name
                      }))}
                      value={countryCode}
                      onChange={(_name: string, value: string) => setCountryCode(value)}
                      hideErrorLine
                    />
                  </div>
                ) : (
                  <div className='mb-3'>
                    <CustomInput
                      type='static'
                      color='alternative'
                      label={tr('CHECKOUT.PAYMENT.COUNTRY', 'Country')}
                      value={translateCountryName(countryCode)}
                      hideErrorLine
                    />
                  </div>
                )}

                <SelectMethod className='use-grid' method={paymentMethod} setMethod={setPaymentMethod} />

                {isValidArray(fields) ? (
                  <>
                    <div className='subtitle mt-3 mb-1'>
                      {tr('CHECKOUT.PAYMENT.DETAILS_CAPTION', 'Enter your details')}
                    </div>

                    {fields.map((item, index) => {
                      const { name, type, defaultLabel, translationKey, required } = item

                      if (name === 'bankChoiceId') {
                        return (
                          <CustomInput
                            key={index}
                            type='select'
                            label={tr('CHECKOUT.PAYMENT.BANK', 'Bank')}
                            {...formValues.registerInput(name, handleBankSelected)}
                            options={banks?.map((bank: SupportedBank) => {
                              const { bankChoiceId, name } = bank
                              return { value: bankChoiceId, label: name }
                            })}
                            required={required}
                            hideErrorLine
                          />
                        )
                      }

                      if (name === 'currency') {
                        return (
                          <CustomInput
                            key={index}
                            type='select'
                            label={tr(translationKey, defaultLabel)}
                            {...formValues.registerInput(name)}
                            options={checkoutCurrencies?.map((currency: CurrencyChoice) => {
                              const { currencyCode, name } = currency
                              return { value: currencyCode, label: name }
                            })}
                            required={required}
                            hideErrorLine
                          />
                        )
                      }
                      if (name === 'documentId' && paymentMethod === 'PIX') {
                        return (
                          <CustomInput
                            key={index}
                            type={type || 'text'}
                            label={tr('MERCHANTS.CHECKOUT.FIELDS.GENERAL.CPF_CNPJ', tr(translationKey, defaultLabel))}
                            {...formValues.registerShort(name)}
                            onChange={(name: string, value: string) =>
                              formValues.setValue(name, value.replace(/[ ./-]/g, ''))
                            }
                            required={required}
                            hideErrorLine
                            autoComplete='off'
                          />
                        )
                      }

                      return (
                        <CustomInput
                          key={index}
                          type={type || 'text'}
                          label={tr(translationKey, defaultLabel)}
                          {...formValues.registerInput(name)}
                          required={required}
                          autoComplete='off'
                          hideErrorLine
                        />
                      )
                    })}
                  </>
                ) : null}

                <div className='buttons'>
                  <Button
                    type='button'
                    color='primary'
                    text={tr('CHECKOUT.PAYMENT.BUTTON_PAY', 'Pay')}
                    onClick={handlePay}
                    disabled={isEmptyString(paymentMethod)}
                    loading={checkoutPayFetching}
                    data-test='button-pay'
                    iconPosition='right'
                    icon='right-arrow'
                  />
                </div>
              </PaymentContainer>

              {showCancelButton ? (
                <CancelContainer>
                  <Link to='#' onClick={handleCancelClick}>
                    {tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
                  </Link>
                </CancelContainer>
              ) : null}
            </>
          )}

        {step === CheckoutFlowStep.DEPOSIT_RESULT && (
          <DepositResult response={prepareResponse()} onBack={handleStartOver} logEvent={logEvent} />
        )}
      </CheckoutContent>
    </CheckoutContainer>
  )
}

export default Checkout
